.form-control.disabled {
  opacity: 0.5 !important;
  background: #f2f2f2 !important;
}

.form-control:disabled,
.form-control[readonly] {
  background: #f2f2f2 !important;
  opacity: 0.5 !important;
}
