#LotsDeliver {
  h6 {
    button {
      text-decoration: underline !important;
      color: #0075ff !important;
      cursor: pointer !important;
      padding: 0rem;
      background: transparent;
      border: none;
    }
  }
}
